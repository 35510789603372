import React, { useState, useContext, useEffect } from "react";

import { mod } from "../utils";
import { Base, LimitMaxWidth, SectionHeading, ModalContext } from "../components/layout";
import DemoModal from "../components/modals/DemoModal";
import HealthAreasSection from "../components/page-sections/HealthAreasSection";
import DemoAndEmployerBannerSection from "../components/page-sections/DemoAndEmployerBannerSection";
import HeroCtaSection from "../components/page-sections/HeroCtaSection";
import SEO from "../components/seo";
import TrackingGradientIcon from "../assets/svg/icon-gradient-tracking";
import OneToOneGradientIcon from "../assets/svg/icon-gradient-121";
import ContentGradientIcon from "../assets/svg/icon-gradient-content";
import CarePlansGradientIcon from "../assets/svg/icon-gradient-care-plans";
import TrackingGradientMIcon from "../assets/svg/icon-gradient-tracking-m";
import OneToOneGradientMIcon from "../assets/svg/icon-gradient-121-m";
import ContentGradientMIcon from "../assets/svg/icon-gradient-content-m";
import CarePlansGradientMIcon from "../assets/svg/icon-gradient-care-plans-m";
import CarouselSelectedIcon from "../assets/svg/icon-carousel-selected";
import CarouselNotSelectedIcon from "../assets/svg/icon-carousel-not-selected";
import InnovativeIcon from "../assets/svg/benefits-innovative";
import ReachIcon from "../assets/svg/benefits-reach";
import DataIcon from "../assets/svg/benefits-data";
import heroWebp from "../assets/images/hero-health-plans.webp";
import introPng from "../assets/images/intro-health-plans.png";
import introAvif from "../assets/images/intro-health-plans.avif";
import introWebp from "../assets/images/intro-health-plans.webp";

const bottomBanner = <DemoAndEmployerBannerSection title="Let's deliver outstanding healthcare together." subtitle="Contacts us to schedule a demo." showEmployerReferral={false} />

export const Head = () => <SEO title="Syrona Health | Insurance Plans" />

const HealthPlansPage: React.FC = () => <Base bottomBanner={bottomBanner}><HealthPlansContent /></Base>

export default HealthPlansPage;


const HealthPlansContent: React.FC = () => {
  const { setModal } = useContext(ModalContext);

  const ctaAction = () => {
    setModal(<DemoModal/>);
  }

  return <>
    <HeroCtaSection 
      image={heroWebp}
      heading={<>Comprehensive Care</>}
      subheading="for complex journeys."
      text="Level up your proposition and give your members access to the support they deserve for life's big or small moments  that are traditionally overlooked."
      cta="Book a demo"
      ctaAction={ctaAction}
    />
    <IntroSection />
    <BenefitsSection />
    <HealthPlansSection />
    <HealthAreasSection backgroundClass="bg-white" title="The health journeys we cover" />
  </>
}

const IntroSection: React.FC = () => <>
  <div className="py-12">
    <LimitMaxWidth>
      <div className="flex max-md:flex-col max-md:space-y-8 justify-center items-center">
        <picture className="md:w-1/2 max-md:max-w-xl">
          <source srcSet={introAvif} type="image/avif" />
          <source srcSet={introWebp} type="image/webp" />
          <img src={introPng} alt=""/>
        </picture>
        <div className="max-md:max-w-2xl md:w-1/2 lg:h-48 md:max-lg:h-64">
          <div className="md:z-10 lg:-ml-24 md:max-lg:-ml-12 bg-white rounded-md shadow-sym-2xl flex flex-col p-12">
            <h4 className="max-sm:text-m-t1 sm:text-h4 font-dm text-black">
              SORA: A solution to create health equity
            </h4>
            <p className="max-sm:text-m-b sm:text-b2 font-nun text-black">
              Our digital health app, SORA, can support your members throughout their entire lives. As your members navigate complex health journeys, our health plans grow with them. 
            </p>
          </div>
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>

const benefits = [
  {
    title: "Innovative Care Strategy",
    text: "Health coaching is woven into our multidisciplinary care model.",
    icon: InnovativeIcon,
  },
  {
    title: "Enhanced For Wider Reach",
    text: "We help you differentiate yourself and increase member satisfaction.",
    icon: ReachIcon,
  },
  {
    title: "Data-Driven Thesis",
    text: "Our partners see lower claims and gain unparalleled population risk insights.",
    icon: DataIcon,
  },
]

const BenefitsSection: React.FC = () => <>
  <div className="pb-12">
    <LimitMaxWidth>
      <SectionHeading>
      Benefits of adding Syrona to your proposition
      </SectionHeading>
      <div className="mt-12 flex justify-center items-center">
        <div className="flex max-md:flex-col max-md:space-y-8 md:space-x-8">
          {benefits.map(b => <>
            <div className="w-80 shadow-sym-2xl rounded-lg flex flex-col justify-center items-center space-y-6 p-12">
              <b.icon />
              <h4 className="max-sm:text-m-h sm:text-s1 font-nunb text-black text-center">{b.title}</h4>
              <p className="max-sm:text-m-b sm:text-b1 font-nun text-black text-center">{b.text}</p>
            </div>
          </>)}
        </div>
      </div>
    </LimitMaxWidth>
  </div>
</>

const healthPlans = [
  {
    title: "Health Tracking",
    text: "Log your symptoms and daily activities to track your health.",
    icon: TrackingGradientIcon,
    mIcon: TrackingGradientMIcon,
  },
  {
    title: "1-1 with Experts",
    text: "Schedule sessions with our health experts 1-1 on chat or video.",
    icon: OneToOneGradientIcon,
    mIcon: OneToOneGradientMIcon,
  },
  {
    title: "Content",
    text: "Educational content to empower  people on a similar journey.",
    icon: ContentGradientIcon,
    mIcon: ContentGradientMIcon,
  },
  {
    title: "Care Plans",
    text: "Get personalised care plans and actionable health insights.",
    icon: CarePlansGradientIcon,
    mIcon: CarePlansGradientMIcon,
  },
]

const HealthPlansSection: React.FC = () => {
  const [selected, setSelected] = useState(0);
  const [rotationTimeout, setRotationTimeout] = useState<NodeJS.Timeout|null>(null);
  const updateSelectedTimeout = 8 * 1000;

  useEffect(() => {
    rotationTimeout && clearTimeout(rotationTimeout)
    const timeout = setTimeout(() => setSelected(mod(selected+1, healthPlans.length)), updateSelectedTimeout);
    setRotationTimeout(timeout)
  }, [selected])

  return <>
    <div className="py-12 bg-gradient-to-r from-syr-main to-syr-snd">
      <LimitMaxWidth>
        <SectionHeading>
          <div className="text-white">What's included?</div>
        </SectionHeading>
        <p className="max-sm:text-m-t3 sm:text-s2 font-nun text-white text-center">
          We offer a wealth of services that your members can benefit from.
        </p>
        <div className="pt-8 md:hidden flex md:h-0 justify-center items-center">
            <div className="w-72 h-56 flex flex-col justify-start items-center px-8">
              <div className="scale-75">
                {healthPlans[selected].mIcon({})}
              </div>
              <h4 className="mt-4 max-sm:text-m-t1 sm:text-s1 font-nunb text-white text-center">
                {healthPlans[selected].title}
              </h4>
              <p className="max-sm:text-m-b sm:text-b2 font-nun text-white text-center">
                {healthPlans[selected].text}
              </p>
            </div>
        </div>
        <div className="md:hidden flex justify-center space-x-4">
          {healthPlans.map((_, idx) => <>
            <button onClick={() => setSelected(idx)}>
              {idx==selected ? <CarouselSelectedIcon/> : <CarouselNotSelectedIcon/>}
            </button>
          </>)}
        </div>
        <div className="pt-8 max-md:hidden flex justify-center">
          <div className="grid content-start justify-items-center lg:grid-cols-4 md:max-lg:grid-cols-2 gap-8">
            {healthPlans.map(hp => <>
              <div className="w-72 flex flex-col justify-start items-center">
                <div className="scale-75">
                  <hp.icon />
                </div>
                <h4 className="text-s1 font-nunb text-white text-center">{hp.title}</h4>
                <p className="text-m-b font-nun text-white text-center">{hp.text}</p>
              </div>
            </>)}
          </div>
        </div>
      </LimitMaxWidth>
    </div>
  </>
}
