import React from "react";

const ContentGradientIcon: React.FC = () => <>
  <svg width="82" height="82" viewBox="0 0 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="82" height="82" rx="8" fill="white"/>
  <path d="M41 23.7252V61.2252M30.375 31.2252H24.75M32.25 38.7252H24.75M66 51.8502V21.6752C66 18.6752 63.55 16.4502 60.575 16.7002H60.425C55.175 17.1502 47.2 19.8252 42.75 22.6252L42.325 22.9002C41.6 23.3502 40.4 23.3502 39.675 22.9002L39.05 22.5252C34.6 19.7502 26.65 17.1002 21.4 16.6752C18.425 16.4252 16 18.6752 16 21.6502V51.8502C16 54.2502 17.95 56.5002 20.35 56.8002L21.075 56.9002C26.5 57.6252 34.875 60.3752 39.675 63.0002L39.775 63.0502C40.45 63.4252 41.525 63.4252 42.175 63.0502C46.975 60.4002 55.375 57.6252 60.825 56.9002L61.65 56.8002C64.05 56.5002 66 54.2502 66 51.8502Z" stroke="url(#paint0_linear_4784_18035)" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
  <linearGradient id="paint0_linear_4784_18035" x1="16" y1="16.6562" x2="74.9339" y2="35.7358" gradientUnits="userSpaceOnUse">
  <stop stop-color="#73314F"/>
  <stop offset="0.0001" stop-color="#73314F"/>
  <stop offset="1" stop-color="#275C61"/>
  </linearGradient>
  </defs>
  </svg>
</>

export default ContentGradientIcon;
